import React from "react";
import MessageCenterDetail from "./components/MessageCenterDetail";
import SitemapRouteHandler from "./SitemapRouteHandler";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import { blueGrey } from "@mui/material/colors";
import Redirect from "./components/Redirect";
import AllItemsPage from "./components/AllItemsPage";
import DefaultPage from "./components/DefaultPage";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  useParams,
} from "react-router-dom";
import NotFound from "./components/NotFound";

import "./App.css";

interface Params extends Record<string, string | undefined> {
  param: string;
}

const theme = createTheme({
  palette: {
    primary: blueGrey,
    secondary: blueGrey,
  },
});

const MessageCenterRouteHandler: React.FC = () => {
  const { param } = useParams<Params>();

  if (!param) {
    // Handle the case where param is undefined, for example by redirecting to a fallback route
    return <Redirect url={"https://changepilot.cloud"} />;
  }

  if (param.toUpperCase().startsWith("MC")) {
    return <MessageCenterDetail />;
  } else {
    return <NotFound />;
  }
};

function App() {
  const ChangePilotHomePage = "https://changepilot.cloud";
  return (
    <ThemeProvider theme={theme}>
      <Router>
        <Routes>
          <Route path="/:param" element={<MessageCenterRouteHandler />} />
          <Route path="/sitemapgen" element={<SitemapRouteHandler />} />
          <Route path="/" element={<Redirect url={ChangePilotHomePage} />} />
          <Route path="/beta" element={<DefaultPage />} />
          <Route path="/all" element={<AllItemsPage />} />
          <Route path="*" element={<NotFound />} />
        </Routes>
      </Router>
    </ThemeProvider>
  );
}

export default App;
