import React from "react";
import { useAuth0 } from "@auth0/auth0-react";
import useMyStatus from "../services/useMyStatus";
import { CardContent } from "@mui/material";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";

const Profile = () => {
  const { user, isAuthenticated, isLoading } = useAuth0();
  const { data } = useMyStatus();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return (
      <>
        {user && (
          <Box sx={{ minWidth: 275, maxWidth: "25%", paddingBottom: "3rem" }}>
            <Card variant="outlined">
              <CardContent>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 14 }}
                >
                  {user.email}
                </Typography>
                <Typography
                  gutterBottom
                  sx={{ color: "text.secondary", fontSize: 12 }}
                >
                  License Type: {data?.membershipType}{" "}
                  {data?.daysLeftText !== null
                    ? "(" + data?.daysLeftText + ")"
                    : null}
                </Typography>
              </CardContent>
            </Card>
          </Box>
        )}
      </>
    );
  }

  return <></>;
};

export default Profile;
