import React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import useGetPowerBIUsageToken from "../services/useGetPowerBIUsageToken";
import { PowerBIEmbed } from "powerbi-client-react";
import { models } from "powerbi-client";

import "../styles/pbiembed.css";

const PowerBiChangePilotReport = () => {
  const { isAuthenticated, isLoading } = useAuth0();
  const token = useGetPowerBIUsageToken();

  if (isLoading) {
    return <div>Loading ...</div>;
  }

  if (isAuthenticated) {
    return (
      <>
        {token && token.data && (
          <PowerBIEmbed
            embedConfig={{
              type: "report", // Supported types: report, dashboard, tile, visual and qna
              id: "328fad1f-ef7d-4050-8e7c-2e0c2928b244",
              embedUrl: undefined,
              accessToken: token.data, // Keep as empty string, null or undefined
              tokenType: models.TokenType.Embed,
              settings: {
                panes: {
                  filters: {
                    expanded: true,
                    visible: true,
                  },
                },
                background: models.BackgroundType.Default,
              },
            }}
            cssClassName="pbi-embed"
          />
        )}
      </>
    );
  }

  return <></>;
};

export default PowerBiChangePilotReport;
