import React from "react";
import TopMenuBar from "./TopMenuBar";
import LoginButton from "./loginButton";
import LogoutButton from "./logoutButton";
import Profile from "./userProfileInfo";
import Container from "@mui/material/Container";
import PowerBiChangePilotReport from "./powerBIEmbed";

const DefaultPage: React.FC = () => {
  return (
    <>
      <TopMenuBar />
      <Container maxWidth="xl">
        <h1>ChangePilot Portal</h1>

        <Profile />
        <LoginButton />
        <LogoutButton />
        <hr />
        <PowerBiChangePilotReport />
      </Container>
    </>
  );
};

export default DefaultPage;
